<template>
  <div class="fixed-asset-category">
    <div class="tree-part">
      <CategorySetTree
        ref="categoryTreeIns"
        type="asset"
        @updateTreeData="treeDataUpdater"
      />
    </div>
    <div class="form-part">
      <CategorySetForm
        type="asset"
        ref="categoryFormIns"
        @success="successHandler"
      />
    </div>
  </div>
</template>

<script>
import CategorySetTree from "../../components/CategorySetTree";
import CategorySetForm from "../../components/CategorySetForm";
export default {
  name: "fixed-asset-category",
  components: {
    CategorySetTree,
    CategorySetForm,
  },
  methods: {
    treeDataUpdater(datas) {
      this.$refs.categoryFormIns.setFromDatas(datas);
    },
    successHandler(res) {
      this.$refs.categoryTreeIns.initTreeDatas(res);
    },
  },
};
</script>

<style lang="less" scoped>
.fixed-asset-category {
  height: calc(100vh - 90px);
  margin: 10px 30px;
  display: flex;
  gap: 20px;
  .tree-part {
    width: 440px;
    height: 100%;
    background: #ffffff;
    border-radius: 10px;
  }
  .form-part {
    flex: 1;
    background: #ffffff;
    border-radius: 10px;
    overflow: auto;
  }
}
</style> 