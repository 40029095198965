var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "fixed-asset-category" }, [
    _c(
      "div",
      { staticClass: "tree-part" },
      [
        _c("CategorySetTree", {
          ref: "categoryTreeIns",
          attrs: { type: "asset" },
          on: { updateTreeData: _vm.treeDataUpdater },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "form-part" },
      [
        _c("CategorySetForm", {
          ref: "categoryFormIns",
          attrs: { type: "asset" },
          on: { success: _vm.successHandler },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }